export default function debounce(fn, duration) {
    var args, context, handle;
    duration || (duration = 100);

    return function () {
        args = arguments;
        context = this;

        if (handle) {
            clearTimeout(handle);
        }

        setTimeout(function () {
            handle = null;
            fn.apply(context, args);
        }, duration);
    };
};
