import $ from "jquery";
import debounce from "./debounce";

export default $.fn.actionBar = function () {
    // if the selector didn't match any elements, abort.
    if (!this[0]) {
        return this;
    }

    const bodyTag = $("body");

    // if we can / have any, retrieve the stored 'collapsed' state and restore it.
    const isCollapsed = sessionStorage && sessionStorage.getItem("e360.actionBar.collapsed") === "true";
    bodyTag.toggleClass("e360-navs-closed", !!isCollapsed);

    // ensure that the styles are applied for the action-bar.
    this.addClass("e360-action-bar");

    // create the action-bar wrapper.
    const wrapper = this.wrap('<div class="e360-action-bar-wrapper"></div>').parent();

    function fixedActionBarHeight() {
        // Using outerheight true to include margins (alternative fix to the jitterbug), unexpected margins 
        // throw off real estate calculation and cause flipping between docked and undocked states when triggered.
        var actionBarHeight = $(".e360-action-bar").outerHeight(true);
        wrapper.css('min-height', actionBarHeight);
    }

    function manualToggleNav() {
        // If the manual collapse element exists then enable the manual toggle trigger
        if ( $(".e360-navs-collapse-button").length ) {
            $('.e360-navs-collapse-button').click(function (e) {
                e.preventDefault();

                const isClosed = !bodyTag.hasClass("e360-navs-closed");                
                bodyTag.toggleClass("e360-navs-closed", isClosed);

                // store the collapsed state in session storage to remember the collapsed state between page transitions.
                sessionStorage && sessionStorage.setItem("e360.actionBar.collapsed", "" + isClosed);

                if($(".e360-action-bar")) {
                    setTimeout(function () {
                        fixedActionBarHeight();
                    }, 200); // 200ms delay to allow for CSS transitions to complete
                }
            });
        }
    }

    // Ensure that initial height calculations are run once the page has finished loading (and not before)
    $(document).ready(function() {
        fixedActionBarHeight();
        manualToggleNav();
    });

    // Height of action bar may change on resize so the following needs to be recalculated to ensure smooth scrolling
    $(window).resize(debounce(fixedActionBarHeight));

    $(window).scroll(debounce(function () {
        const scrollpos = $(window).scrollTop();

        // Make sure the fixed position state is removed when scroll position is zero as this will otherwise
        // interferes with any manual docking mechanism.
        if (scrollpos === 0) {
            bodyTag.removeClass("e360-fixed-action-bar");
            return;
        }

        const cropViewPos = wrapper.offset().top - scrollpos;
        bodyTag.toggleClass("e360-fixed-action-bar", cropViewPos <= 0);

    }));

    return this;
};

